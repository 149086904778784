/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Image, Text, ContactForm } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="pb--60 pt--60" name={"wstęp"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" content={"Autoserwis Wrona"}>
              </Subtitle>

              <Title className="title-box fs--72" content={"+48 797 811 2X0<br>info@twoje-stronyy.com"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kontakt-1"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1050}}>
              
              <Image className="pb--0 pt--0" alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/156/img-2_s=350x_.jpg"} svg={false} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/156/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/156/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/156/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/156/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/156/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"kontakt-2"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Text className="text-box fs--18" content={"Pańska 18,<br>Warszawa, Polska<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1rkryxn pb--60 pt--60" name={"kontakt-3"} parallax={false} css={css`
        
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Masz pytanie?</span><br>"}>
              </Title>

              <ContactForm className="--shape2 --style1 fs--18 mt--40" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Pełne imię","type":"text","required":true,"placeholder":"Wpisz pełne imię"},{"name":"Kontakt e-mail","type":"email","required":true,"placeholder":"Wpisz swój e-mail"},{"name":"Numer telefonu","type":"text","placeholder":"Telefon +480"},{"name":"Wiadomość","type":"textarea","required":true,"placeholder":"Wpisz tekst wiadomości"},{"name":"Wyślij","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"stopka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Autoserwis Wrona</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: white;\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">O nas</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: white;\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: white;\">+48 797 811 2X0<br>Pańska 18, Warszawa</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: white;\">REGON:: 12345678<br>Utworzone przez<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}